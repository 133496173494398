import React from "react"
import Iframe from "react-iframe"
import FlexContainer from "../FlexContainer"
import { Whitespace } from "../Layout"

const SkyMagazine = () => {
  return (
    <FlexContainer justifyContent="center">
      <Whitespace />

      <Iframe
        src="https://online.flipbuilder.com/amec/oujw/"
        height="768px"
        width="1024px"
        id="myId"
      />
    </FlexContainer>
  )
}

export default SkyMagazine
