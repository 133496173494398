import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

const StyledFlexContainer = styled.div`
  display: flex;
  justify-content: ${props => props.justifyContent};
  @media screen and (max-width: 39.9375em) {
    display: block;
  }
`

const FlexContainer = ({ justifyContent, children }) => {
  return (
    <StyledFlexContainer justifyContent={justifyContent}>
      {children}
    </StyledFlexContainer>
  )
}

FlexContainer.defaultProps = {
  justifyContent: "flex-start",
}
FlexContainer.propTypes = {
  justifyContent: PropTypes.oneOf([
    "flex-start",
    "flex-end",
    "center",
    "space-between",
    "space-around",
    "space-evenly",
  ]),
  children: PropTypes.node,
}

export default FlexContainer
