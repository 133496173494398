import React from "react"
import SkyMagazine from "../components/SkyMagazine"
import { LayoutSkyMag } from "../components/Layout"

const SkysTheLimitMagazinePage = () => {
  return (
    <LayoutSkyMag>
      <SkyMagazine />
    </LayoutSkyMag>
  )
}

export default SkysTheLimitMagazinePage
